// import defaultPassport from '../data/passports/default.json';
 
import dppF1004 from '../data/passports/dpp-data-flt-1-004.json';
import dppF1005 from '../data/passports/dpp-data-flt-1-005.json';
import dppF1006 from '../data/passports/dpp-data-flt-1-006.json';
import dppF2004 from '../data/passports/dpp-data-flt-2-004.json';
import dppF2005 from '../data/passports/dpp-data-flt-2-005.json';
import dppF2006 from '../data/passports/dpp-data-flt-2-006.json';
import dppF1016 from '../data/passports/dpp-data-flt-1-016.json';
import dppF1017 from '../data/passports/dpp-data-flt-1-017.json';
import dppF1018 from '../data/passports/dpp-data-flt-1-018.json';
import dppF1019 from '../data/passports/dpp-data-flt-1-019.json';
import dppF1021 from '../data/passports/dpp-data-flt-1-021.json';
import dppF1023 from '../data/passports/dpp-data-flt-1-023.json';
import dppF1024 from '../data/passports/dpp-data-flt-1-024.json';
import dppL1001 from '../data/passports/dpp-data-loop-1-001.json';

export const fetchProductData = async (productId) => {
    try {
        switch (productId) {
            case "moo-flt-1-004":
                return dppF1004
            case "moo-flt-1-005":
                return dppF1005
            case "moo-flt-1-006":
                return dppF1006
            case "moo-flt-2-004":
                return dppF2004
            case "moo-flt-2-005":
                return dppF2005
            case "moo-flt-2-006":
                return dppF2006
            case "moo-flt-1-016":
                return dppF1016
            case "moo-flt-1-017":
                return dppF1017
            case "moo-flt-1-018":
                return dppF1018
            case "moo-flt-1-019":
                return dppF1019
            case "moo-flt-1-021":
                return dppF1021
            case "moo-flt-1-023":
                return dppF1023
            case "moo-flt-1-024":
                return dppF1024
            case "moo-loop-1-001":
                return dppL1001
            default:
                throw new Error('ERROR: Not a valid passport-id.');
        }
        // const response = await fetch(`https://dpp-node-app-752445509011.europe-west4.run.app/passport/${productId}`);
        // if (!response.ok) throw new Error('Network response was not ok');
        // const data = await response.json();
        // return data;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
  };