import React from 'react';
import './StepsSectionComponent.css';
import { ReactComponent as DefaultIcon } from '../../data/moo-dpp-journey-icons/default.svg';
import { ReactComponent as LeafIcon } from '../../data/moo-dpp-journey-icons/leaf.svg';
import { ReactComponent as MachineIcon } from '../../data/moo-dpp-journey-icons/machine.svg';
import { ReactComponent as ScissorIcon } from '../../data/moo-dpp-journey-icons/scissors.svg';
import { ReactComponent as BoxIcon } from '../../data/moo-dpp-journey-icons/box.svg';

const icons = {
  "machine": MachineIcon,
  "scissor": ScissorIcon,
  "box": BoxIcon,
  "leaf": LeafIcon
};


const StepsSectionComponent = ({ data }) => {

  const DynamicIcon = ({ iconName }) => {
    const IconComponent = icons[iconName] || DefaultIcon;
    return <IconComponent />;
  };

  return (
    <div className="content-section steps-section">
      <div className="steps-container">
      {data.stepComponents.map((step, index) => (
        <div className="step-block">
          <div className="step-number">0{index+1}</div>
          <div className="step-prog">
            <div className="step-icon">
              <DynamicIcon iconName={step.stepIcon} />
            </div>
            <div className="step-line"></div>
          </div>
          <div key={index} className="step-content">
            <span>{step.title}</span>
            <div className="substep-container">
              {step.substepComponents.map((substep, idx) => (
                <div key={idx} className="substep-block">
                  <img src={substep.image} alt={step.title}/>
                  {/* <img src='./default-step-photo.png' /> */}
                  <div>
                    {substep.text.map((paragraph, index) => (
                      <p>{paragraph}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      </div>
    </div>
  );
};

export default StepsSectionComponent;