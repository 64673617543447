import React from 'react';
import './LinksSectionComponent.css';

const LinksSectionComponent = ({ data }) => {
  return (
    <div className="content-section links-section">
      <h4>{data.title}</h4>
      <div className="links-container">
        {data.list.map((link, index) => (
          <div key={index} className="link-item">
            <a href={link.value}>{link.title}</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LinksSectionComponent;