import React from 'react';
import './TextSectionComponent.css';

const TextSectionComponent = ({ data }) => {
  return (
    <div className="content-section text-section">
      <h4>{data.title}</h4>
      {data.text.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </div>
  );
};

export default TextSectionComponent;
