import React from 'react';
import StoryComponent from '../StoryComponent/StoryComponent';
import './MainComponent.css';

const MainComponent = ({ data }) => {
  // If no story-components are available, display a message
  if (!data || !data.storyComponents || data.storyComponents.length === 0) {
    return <div className="no-stories">No data...</div>;
  }

  return (
    <div className="main-component">
      {data.storyComponents.map((story, index) => (
        <StoryComponent key={index} story={story} />
      ))}
    </div>
  );
};

export default MainComponent;
