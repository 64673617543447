import React, { useEffect, useState } from 'react';
import { fetchProductData } from './utils/api';
import HeaderComponent from './components/HeaderComponent/HeaderComponent';
import MainComponent from './components/MainComponent/MainComponent';
import FooterComponent from './components/FooterComponent/FooterComponent';
import './App.css';  // Placeholder for global styling

const App = () => {
  const [productData, setProductData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [isMobile, setIsMobile] = useState(false);
  const isMobile = true;

  const productId = window.location.pathname.split('/')[1];  // Get product id from URL

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await fetchProductData(productId);
        setProductData(data.passportContent.product);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [productId]);

  // useEffect(() => {
  //   // Check if the device is mobile
  //   const checkMobileDevice = () => {
  //     const userAgent = window.navigator.userAgent;
  //     setIsMobile(/Mobi|Android/i.test(userAgent)); // Check for mobile user agents
  //   };
  //   checkMobileDevice();
  // }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return (
    <div className="App">
      <div className="welcome-message">
        <h1>Welcome...</h1>
        <p>dpp.emissa.eu/<span>{"{"}your-passport-id{"}"}</span></p>
      </div>
    </div>
  );

  if (!isMobile) {
    return (
      <div className="App">
        <div className="mobile-message">
          <h1>Sorry, mobile devices only.</h1>
          <p>Please use a mobile phone to view the passport content.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <HeaderComponent data={productData.headerComponent} />
      <MainComponent data={productData.mainComponent} />
      <FooterComponent data={productData.footerComponent} />
    </div>
  );
};

export default App;