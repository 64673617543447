import React from 'react';
import './FooterComponent.css';

const FooterComponent = ({ text }) => {
  return (
    <div className="footer-component">
      <span>Powered by <a href="https://www.emissa.eu">Emissa</a></span>
    </div>
  );
};

export default FooterComponent;